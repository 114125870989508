/* eslint-disable jsx-a11y/anchor-is-valid */
import { useUrlGenerator } from '@folklore/routes';
import React from 'react';
// import * as AppPropTypes from '../../lib/PropTypes';
import { FormattedMessage } from 'react-intl';

import Link from './Link';

import styles from '../../../styles/partials/back-header.module.css';

const propTypes = {};

const defaultProps = {};

function BackHeader() {
    const route = useUrlGenerator();
    return (
        <div className={styles.container}>
            <Link url={route('home')} disableModal>
                <h3 className={styles.back}>
                    <FormattedMessage
                        defaultMessage="Retourner à l'accueil"
                        description="Home link"
                    />
                </h3>
            </Link>
            <Link url={route('about')} disableModal>
                <span className={styles.link}>
                    <FormattedMessage defaultMessage="À propos" description="Slogan" />
                </span>
            </Link>
        </div>
    );
}

BackHeader.propTypes = propTypes;
BackHeader.defaultProps = defaultProps;

export default BackHeader;

/* eslint-disable jsx-a11y/media-has-caption */

/* eslint-disable react/jsx-props-no-spreading, no-param-reassign */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useRef } from 'react';

import useVideo from '../../hooks/useVideo';

import Button from '../buttons/Button';

import styles from '../../../styles/partials/video.module.css';

const propTypes = {
    autoPlay: PropTypes.bool,
    initialMuted: PropTypes.bool,
    withEmbedControls: PropTypes.bool,
    preventPause: PropTypes.bool,
    // fullscreen: PropTypes.bool,
    // onFullscreenClick: PropTypes.func,
    video: PropTypes.shape({
        url: PropTypes.string,
        width: PropTypes.number,
        height: PropTypes.number,
        duration: PropTypes.number,
    }),
    width: PropTypes.number,
    height: PropTypes.number,
    // eslint-disable-next-line react/forbid-prop-types
    apiRef: PropTypes.object,
    onPlay: PropTypes.func,
    onPause: PropTypes.func,
    onEnd: PropTypes.func,
    onMetadataChange: PropTypes.func,
    onVolumeChange: PropTypes.func,
    onBufferStart: PropTypes.func,
    onBufferEnded: PropTypes.func,
    onTimeUpdate: PropTypes.func,
    onLoaded: PropTypes.func,
    onClick: PropTypes.func,
    className: PropTypes.string,
    iframeClassName: PropTypes.string,
    videoClassName: PropTypes.string,
};

const defaultProps = {
    autoPlay: false,
    initialMuted: false,
    withEmbedControls: true,
    preventPause: false,
    // fullscreen: null,
    // onFullscreenClick: null,
    video: null,
    width: null,
    height: null,
    apiRef: null,
    onPlay: null,
    onPause: null,
    onEnd: null,
    onMetadataChange: null,
    onVolumeChange: null,
    onBufferStart: null,
    onBufferEnded: null,
    onTimeUpdate: null,
    onLoaded: null,
    onClick: null,
    className: null,
    iframeClassName: null,
    videoClassName: null,
};

const Video = ({
    autoPlay,
    initialMuted,
    withEmbedControls,
    preventPause,
    // fullscreen,
    // onFullscreenClick,
    video,
    width,
    height,
    apiRef,
    onPlay,
    onPause,
    onEnd,
    onMetadataChange,
    onVolumeChange,
    onBufferStart,
    onBufferEnded,
    onTimeUpdate,
    onLoaded,
    onClick,
    className,
    iframeClassName,
    videoClassName,
}) => {
    const {
        url = null,
        iframeUrl = null,
        provider: videoProvider,
        id: videoId,
        width: videoWidth,
        height: videoHeight,
        duration: videoDuration,
    } = video || {};

    const lastVideoRef = useRef(video);
    if (lastVideoRef.current === null) {
        lastVideoRef.current = video;
    } else {
        lastVideoRef.current = video;
    }

    const finalUrl = iframeUrl || url;
    let realIframeUrl =
        lastVideoRef.current !== null
            ? lastVideoRef.current.iframeUrl || lastVideoRef.current.url
            : null;
    if (videoProvider === 'youtube' && autoPlay && realIframeUrl !== null) {
        realIframeUrl = `${realIframeUrl}${
            realIframeUrl.indexOf('?') === -1 ? '?' : '&'
        }autoplay=1`;
    }
    const { ref, ...api } = useVideo(finalUrl, {
        muted: initialMuted,
        autoplay: autoPlay,
        controls: withEmbedControls,
        type: videoProvider,
        videoId,
        width: videoWidth,
        height: videoHeight,
        duration: videoDuration,
        onEnd,
        onMetadataChange,
        onPlay,
        onPause,
        onBufferStart,
        onBufferEnded,
        onTimeUpdate,
        onVolumeChange,
        onLoaded,
    });

    if (apiRef !== null) {
        apiRef.current = api;
    }

    const { muted, playing, buffering, play, pause, loaded, unmute } = api;

    const loading = finalUrl !== null && (!loaded || buffering);
    const paused = !playing && !loading && loaded;

    // const onMuteClick = useCallback(
    //     (e) => {
    //         e.stopPropagation();
    //         if (muted) {
    //             unmute();
    //         } else {
    //             mute();
    //         }
    //     },
    //     [muted, unmute, mute],
    // );

    const onInnerClick = useCallback(() => {
        if (loading) {
            return;
        }

        if (onClick !== null) {
            onClick();
        }

        if (muted && playing) {
            unmute();
            return;
        }

        if (!playing) {
            play();
        } else if (!preventPause) {
            pause();
        }
    }, [preventPause, onClick, playing, play, pause, loading, muted, unmute]);

    const el = useRef(null);

    // const onFullScreenClickCustom = useCallback(
    //     (e) => {
    //         e.stopPropagation();
    //         if (onFullscreenClick !== null) {
    //             onFullscreenClick();
    //         } else if (screenfull.isEnabled) {
    //             screenfull.toggle(el.current);
    //         }
    //     },
    //     [onFullscreenClick],
    // );

    return (
        <div
            ref={el}
            className={classNames([
                styles.container,
                {
                    [styles.paused]: paused,
                    [styles.loading]: loading,
                    [styles.muted]: muted,
                    [styles.withEmbedControls]: withEmbedControls,
                    [styles.isNative]: videoProvider === null,
                    [className]: className !== null,
                },
            ])}
        >
            {url !== null ? (
                <div className={styles.inner}>
                    {!withEmbedControls ? (
                        <Button className={styles.overlay} onClick={onInnerClick} />
                    ) : null}
                    {videoProvider === 'youtube' || videoProvider === 'vimeo' ? (
                        <iframe
                            key={video !== null ? `video-${finalUrl}` : 'video'}
                            className={classNames([
                                styles.iframe,
                                { [iframeClassName]: iframeClassName !== null },
                            ])}
                            title="video"
                            frameBorder={0}
                            src={realIframeUrl}
                            ref={ref}
                            allow="autoplay;"
                            allowFullScreen
                            width={width}
                            height={height}
                        />
                    ) : null}
                    {videoProvider === null ? (
                        <video
                            key={video !== null ? `video-${video.url}` : 'video'}
                            className={classNames([
                                styles.video,
                                { [videoClassName]: videoClassName !== null },
                            ])}
                            src={lastVideoRef.current !== null ? lastVideoRef.current.url : null}
                            type="video/mp4"
                            ref={ref}
                            style={{ width, height }}
                            controls
                        />
                    ) : null}
                </div>
            ) : null}
        </div>
    );
};

Video.propTypes = propTypes;
Video.defaultProps = defaultProps;

export default React.forwardRef((props, ref) => <Video apiRef={ref} {...props} />);

import PropTypes from 'prop-types';
import React from 'react';

import { useHasSeenModal } from '../../contexts/ModalsContext';
import PlayVideo from '../buttons/PlayVideo';
import Modal from '../modals/Modal';
import Footer from '../partials/Footer';
import Header from '../partials/Header';
import Menu from '../partials/Menu';

import styles from '../../../styles/layouts/main.module.css';

const propTypes = {
    page: PropTypes.shape({
        title: PropTypes.string,
    }),
    video: PropTypes.string,
    children: PropTypes.node.isRequired,
};

const defaultProps = {
    page: null,
    video: null,
};

function MainLayout({ page, video, children }) {
    const hasSeenModal = useHasSeenModal();
    return (
        <div className={styles.container}>
            <header className={styles.top}>
                <Header />
                <Menu />
            </header>
            <main className={styles.inner}>
                <div className={styles.content}>{children}</div>
            </main>
            <footer className={styles.footer}>
                <Footer />
            </footer>
            <Modal page={page} video={video} />
            {hasSeenModal ? <PlayVideo className={styles.playVideo} /> : null}
        </div>
    );
}

MainLayout.propTypes = propTypes;
MainLayout.defaultProps = defaultProps;

export default MainLayout;

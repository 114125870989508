/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

// import * as AppPropTypes from '../../lib/PropTypes';
import PageMeta from '../partials/PageMeta';

import styles from '../../../styles/pages/error.module.css';

export const messages = defineMessages({
    metaTitle401: {
        id: 'meta.title_401',
        defaultMessage: 'Error 401',
    },
    title401: {
        id: 'errors.title_401',
        defaultMessage: 'Error 401',
    },
    description401: {
        id: 'errors.description_401',
        defaultMessage: 'You are not authorized to access this page.',
    },

    metaTitle403: {
        id: 'meta.title_403',
        defaultMessage: 'Error 403',
    },
    title403: {
        id: 'errors.title_403',
        defaultMessage: 'Error 403',
    },
    description403: {
        id: 'errors.description_403',
        defaultMessage: 'Access to this page is forbidden',
    },

    metaTitle404: {
        id: 'meta.title_404',
        defaultMessage: 'Error 404',
    },
    title404: {
        id: 'errors.title_404',
        defaultMessage: 'Error 404',
    },
    description404: {
        id: 'errors.description_404',
        defaultMessage: 'This page doesn’t exist',
    },

    metaTitle500: {
        id: 'meta.title_500',
        defaultMessage: 'Error 500',
    },
    title500: {
        id: 'errors.title_500',
        defaultMessage: 'Error 500',
    },
    description500: {
        id: 'errors.description_500',
        defaultMessage: 'There was an error',
    },
    gotoHome: {
        id: 'errors.goto_home',
        defaultMessage: 'Go home',
    },
});

const propTypes = {
    statusCode: PropTypes.number,
};

const defaultProps = {
    statusCode: 404,
};

function ErrorPage({ statusCode }) {
    // const finalCode = parseInt(statusCode, 10) || 404;
    return (
        <div className={styles.container}>
            <PageMeta title="Erreur 404" />
            <div className={styles.inner}>
                <h1 className={styles.title}>
                    <FormattedMessage defaultMessage="Erreur 404" description="Page title" />
                </h1>
                <p className={styles.description}>
                    <FormattedMessage
                        defaultMessage="Cette nouvelle n'existe pas."
                        description="Page description"
                    />
                </p>
                <div className={styles.actions}>
                    <a href="/">
                        <FormattedMessage
                            defaultMessage="Retourner à l'accueil"
                            description="Page description"
                        />
                    </a>
                </div>
            </div>
        </div>
    );
}

ErrorPage.propTypes = propTypes;
ErrorPage.defaultProps = defaultProps;

export default ErrorPage;

import PropTypes from 'prop-types';
import React from 'react';

import PlayVideo from '../buttons/PlayVideo';
import Modal from '../modals/Modal';
import BackHeader from '../partials/BackHeader';

import styles from '../../../styles/layouts/main.module.css';

const propTypes = {
    page: PropTypes.shape({
        title: PropTypes.string,
    }),
    video: PropTypes.string,
    children: PropTypes.node.isRequired,
};

const defaultProps = {
    page: null,
    video: null,
};

function PlainLayout({ page, video, children }) {
    return (
        <div className={styles.container}>
            <header className={styles.top}>
                <BackHeader />
            </header>
            <main className={styles.inner}>
                <div className={styles.content}>{children}</div>
            </main>
            <footer className={styles.footer} />
            <Modal page={page} video={video} />
            <PlayVideo className={styles.playVideo} />
        </div>
    );
}

PlainLayout.propTypes = propTypes;
PlainLayout.defaultProps = defaultProps;

export default PlainLayout;

import { RoutesProvider } from '@folklore/routes';
// import messagesFr from '../locale/fr.json';
import { TrackingContainer } from '@folklore/tracking';
import PropTypes from 'prop-types';
import React from 'react';
import { IntlProvider } from 'react-intl';
import { BrowserRouter } from 'react-router-dom';

import { ModalsProvider } from '../contexts/ModalsContext';
// import data from '../data/site';
import Routes from './Routes';

import '../../styles/fonts.css';
import '../../styles/styles.css';

const propTypes = {
    intl: PropTypes.shape({
        locale: PropTypes.string,
        messages: PropTypes.oneOfType([
            PropTypes.objectOf(PropTypes.objectOf(PropTypes.string)),
            PropTypes.objectOf(PropTypes.string),
        ]),
    }),
    routes: PropTypes.objectOf(PropTypes.string),
    data: PropTypes.shape({
        articles: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    meta: PropTypes.shape({
        title: PropTypes.string,
    }),
};

const defaultProps = {
    intl: {
        locale: 'fr',
        messages: { fr: {} },
    },
    routes: {},
    data: null,
    meta: null,
};

function App({ intl, data, meta, routes }) {
    const { locale = 'fr', messages = {} } = intl || {};
    // console.log('data', data, meta);
    // console.log(routes);

    return (
        <IntlProvider locale={locale} messages={messages[locale] || messages}>
            <TrackingContainer>
                <BrowserRouter>
                    <RoutesProvider routes={routes}>
                        <ModalsProvider>
                            <Routes data={data} meta={meta} />
                        </ModalsProvider>
                    </RoutesProvider>
                </BrowserRouter>
            </TrackingContainer>
        </IntlProvider>
    );
}

App.propTypes = propTypes;
App.defaultProps = defaultProps;

export default App;

/* eslint-disable jsx-a11y/anchor-is-valid */

/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';

import { useHasSeenModal, useModals } from '../../contexts/ModalsContext';
import Article from '../partials/Article';
import Grid from '../partials/Grid';
import Link from '../partials/Link';
import PageMeta from '../partials/PageMeta';

import styles from '../../../styles/pages/home.module.css';

const messages = defineMessages({
    metaTitle: {
        defaultMessage: 'Nouvelles | @lasource.info',
        description: 'Page title',
    },
});

const propTypes = {
    // intl: AppPropTypes.intl.isRequired,
    articles: PropTypes.arrayOf(PropTypes.shape({})),
    meta: PropTypes.shape({
        title: PropTypes.string,
    }),
};

const defaultProps = {
    articles: null,
    meta: null,
};

function HomePage({ articles, meta }) {
    const { modal, setModal } = useModals();
    const finalArticles = articles || [];
    const first = finalArticles.find((it, i) => i === 0) || null;
    const second = finalArticles.find((it, i) => i === 1) || null;
    const small = finalArticles.filter((it, i) => i > 1 && i < 5) || [];
    const items = finalArticles.filter((it, i) => i >= 5) || [];

    const hasSeenModal = useHasSeenModal();

    useEffect(() => {
        let timeoutId = null;
        if (!hasSeenModal) {
            timeoutId = setTimeout(() => {
                setModal(modal !== null ? null : { type: 'video' });
            }, 3000);
        }
        return () => {
            clearTimeout(timeoutId);
        };
    }, [hasSeenModal, setModal]);

    return (
        <div className={styles.container}>
            <PageMeta title={messages.metaTitle} {...meta} />
            <h1 className={styles.title}>
                <FormattedMessage
                    defaultMessage="La liberté d’expression en péril ?"
                    description="Page title"
                />
            </h1>
            <div className={styles.top}>
                <Link className={styles.large} share={hasSeenModal ? first : null}>
                    <Article {...first} withShare={hasSeenModal} />
                </Link>
                <Link className={styles.small} share={hasSeenModal ? second : null}>
                    <Article {...second} layout="full" withShare={hasSeenModal} />
                </Link>
            </div>
            {small !== null ? (
                <Grid
                    className={styles.grid}
                    items={small}
                    itemLayout="small"
                    withShare={hasSeenModal}
                />
            ) : null}
            {items !== null ? (
                <Grid className={styles.grid} items={items} withShare={hasSeenModal} />
            ) : null}
            <div className={styles.end}>
                <Link className={styles.button}>
                    <FormattedMessage
                        defaultMessage="Lire plus d'articles"
                        description="Page title"
                    />
                </Link>
            </div>
        </div>
    );
}

HomePage.propTypes = propTypes;
HomePage.defaultProps = defaultProps;

export default HomePage;

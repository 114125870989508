/* eslint-disable jsx-a11y/anchor-is-valid */

/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React from 'react';

import Article from './Article';
import Link from './Link';

import styles from '../../../styles/partials/grid.module.css';

const propTypes = {
    itemLayout: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number })),
    withShare: PropTypes.bool,
};

const defaultProps = {
    itemLayout: 'normal',
    items: [],
    withShare: false,
};

function Grid({ itemLayout, items, withShare }) {
    return (
        <div className={styles.container}>
            {items.map((article) => (
                <Link key={`article-${article?.slug}`} share={withShare ? article : null}>
                    <Article
                        className={styles.article}
                        {...article}
                        layout={itemLayout}
                        withShare={withShare}
                    />
                </Link>
            ))}
        </div>
    );
}

Grid.propTypes = propTypes;
Grid.defaultProps = defaultProps;

export default Grid;

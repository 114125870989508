/* eslint-disable jsx-a11y/anchor-is-valid */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { Link as ReactLink, useNavigate } from 'react-router-dom';

import { useTrackInteraction } from '../../hooks/useTracking';

import { useModals } from '../../contexts/ModalsContext';

import styles from '../../../styles/partials/link.module.css';

const propTypes = {
    url: PropTypes.string,
    share: PropTypes.shape({
        url: PropTypes.string,
        image: PropTypes.string,
    }),
    clearModal: PropTypes.bool,
    disableModal: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.node,
};

const defaultProps = {
    url: null,
    share: null,
    clearModal: false,
    disableModal: false,
    className: null,
    children: null,
};

function Link({ url, share, disableModal, clearModal, className, children }) {
    const { setModal } = useModals();
    const trackInteraction = useTrackInteraction();
    const navigate = useNavigate();

    const onClick = useCallback(
        (e) => {
            if (share !== null) {
                e.preventDefault();
                e.stopPropagation();
                setModal({ type: 'share', share });
                const { path: shareUrl = null } = share || {};
                if (shareUrl !== null) {
                    navigate(shareUrl);
                }
            } else if (clearModal) {
                setModal(null);
                if (typeof window !== 'undefined') {
                    window.scrollTo({ top: 0 });
                }
            } else if (!disableModal) {
                e.preventDefault();
                e.stopPropagation();
                setModal({ type: 'video' });
            }

            if (trackInteraction !== null) {
                trackInteraction(
                    'link',
                    'click',
                    url !== null ? url : 'empty',
                    !disableModal ? 'trigger_modal' : 'not_trigger_modal',
                );
            }
        },
        [share, disableModal, clearModal, setModal, trackInteraction, url],
    );
    return url !== null ? (
        <ReactLink
            to={url}
            className={classNames([styles.container, { [className]: className !== null }])}
            onClick={onClick}
        >
            {children}
        </ReactLink>
    ) : (
        <a
            href="#"
            className={classNames([styles.container, { [className]: className !== null }])}
            rel="noopener noreferrer"
            onClick={onClick}
        >
            {children}
        </a>
    );
}

Link.propTypes = propTypes;
Link.defaultProps = defaultProps;

export default Link;

/* eslint-disable jsx-a11y/control-has-associated-label, jsx-a11y/anchor-is-valid, react/jsx-props-no-spreading */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Share from '../partials/Share';

import styles from '../../../styles/modals/share.module.css';

const propTypes = {
    share: PropTypes.shape({
        title: PropTypes.string,
    }),
    className: PropTypes.string,
};

const defaultProps = {
    share: null,
    className: null,
};

const ShareModal = ({ share, className }) => {
    const { url, share: image = null } = share || {};
    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            <div className={styles.inner}>
                <img className={styles.image} src={image} alt="share" />
                <Share className={styles.share} url={url} />
            </div>
        </div>
    );
};

ShareModal.propTypes = propTypes;
ShareModal.defaultProps = defaultProps;

export default ShareModal;

/* eslint-disable jsx-a11y/anchor-is-valid */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import { useModals } from '../../contexts/ModalsContext';
import PlayIcon from '../icons/Play';
import Button from './Button';

import styles from '../../../styles/buttons/play-video.module.css';

const propTypes = {
    className: PropTypes.string,
};

const defaultProps = {
    className: null,
};

function PlayVideo({ className }) {
    const { modal, setModal } = useModals();
    const onClick = useCallback(() => {
        setModal(modal !== null ? null : { type: 'video' });
    }, [modal, setModal]);

    return (
        <Button
            type="button"
            onClick={onClick}
            className={classNames([styles.container, { [className]: className !== null }])}
        >
            <PlayIcon className={styles.icon} />
        </Button>
    );
}

PlayVideo.propTypes = propTypes;
PlayVideo.defaultProps = defaultProps;

export default PlayVideo;

/* eslint-disable jsx-a11y/control-has-associated-label, react/button-has-type, react/jsx-props-no-spreading */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton,
    XIcon,
} from 'react-share';

import { useTrackInteraction } from '../../hooks/useTracking';
import copyToClipboard from '../../lib/copyToClipboard';

import Button from '../buttons/Button';
import EmailIcon from '../icons/EmailIcon';
import ShareLinkIcon from '../icons/ShareLinkIcon';

import styles from '../../../styles/partials/share.module.css';

const propTypes = {
    className: PropTypes.string,
    itemClassName: PropTypes.string,
    labelClassName: PropTypes.string,
    buttonClassName: PropTypes.string,
    title: PropTypes.string,
    url: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.string),
    iconSize: PropTypes.number,
    onShare: PropTypes.func,
    onClose: PropTypes.func,
    focusable: PropTypes.bool,
};

const defaultProps = {
    className: null,
    itemClassName: null,
    labelClassName: null,
    buttonClassName: null,
    title: null,
    url: null,
    options: null,
    iconSize: 45,
    onShare: null,
    onClose: null,
    focusable: true,
};

const Share = ({
    className,
    itemClassName,
    labelClassName,
    buttonClassName,
    title,
    url,
    options,
    iconSize,
    onShare,
    onClose,
    focusable,
}) => {
    const [linkCopied, setLinkCopied] = useState(false);
    const trackInteraction = useTrackInteraction();

    const onClickCopy = useCallback(
        (e) => {
            e.preventDefault();
            e.stopPropagation();
            copyToClipboard(url).then(() => {
                setLinkCopied(true);
                setTimeout(() => {
                    setLinkCopied(false);
                }, 2000);
            });
            if (trackInteraction !== null) {
                trackInteraction('share', 'click', 'copy_link');
            }
        },
        [url, setLinkCopied, trackInteraction],
    );

    const onShareButtonClick = useCallback(
        (type) => {
            if (onShare !== null) {
                onShare(type);
            }
            if (trackInteraction !== null) {
                trackInteraction('share', 'click', type || 'empty');
            }
        },
        [onShare, trackInteraction],
    );

    const shareButtonProps = useMemo(
        () => ({
            className: classNames([styles.button, { [buttonClassName]: buttonClassName !== null }]),
            url,
            onShareWindowClose: () => {
                if (onClose !== null) {
                    onClose();
                }
            },
        }),
        [url, onClose],
    );

    const shareIconProps = useMemo(
        () => ({
            className: styles.icon,
            size: iconSize,
            bgStyle: {
                fill: 'none',
            },
            iconFillColor: 'currentColor',
        }),
        [iconSize],
    );

    const shareOptions = [
        {
            id: 'facebook',
            button: (
                <FacebookShareButton
                    {...shareButtonProps}
                    quote={title}
                    beforeOnClick={() => {
                        onShareButtonClick('Facebook');
                        return Promise.resolve();
                    }}
                    onClick={(e) => {
                        // e.preventDefault();
                        e.stopPropagation();
                    }}
                    tabIndex={focusable ? null : '-1'}
                >
                    <FacebookIcon {...shareIconProps} />
                    <div
                        className={classNames([
                            styles.label,
                            { [labelClassName]: labelClassName !== null },
                        ])}
                    >
                        Facebook
                    </div>
                    <div className={styles.spacer} style={{ width: `${iconSize}px` }} />
                </FacebookShareButton>
            ),
        },
        {
            id: 'twitter',
            button: (
                <TwitterShareButton
                    {...shareButtonProps}
                    title={title}
                    beforeOnClick={() => {
                        onShareButtonClick('Twitter');
                        return Promise.resolve();
                    }}
                    // onClick={(e) => {
                    //     e.preventDefault();
                    //     e.stopPropagation();
                    // }}
                    tabIndex={focusable ? null : '-1'}
                >
                    <XIcon {...shareIconProps} />
                    <div
                        className={classNames([
                            styles.label,
                            { [labelClassName]: labelClassName !== null },
                        ])}
                    >
                        X (Twitter)
                    </div>
                    <div className={styles.spacer} style={{ width: `${iconSize}px` }} />
                </TwitterShareButton>
            ),
        },
        {
            id: 'linkedin',
            button: (
                <LinkedinShareButton
                    {...shareButtonProps}
                    title={title}
                    beforeOnClick={() => {
                        onShareButtonClick('LinkedIn');
                        return Promise.resolve();
                    }}
                    // onClick={(e) => {
                    //     e.preventDefault();
                    //     e.stopPropagation();
                    // }}
                    tabIndex={focusable ? null : '-1'}
                >
                    <LinkedinIcon {...shareIconProps} />
                    <div
                        className={classNames([
                            styles.label,
                            { [labelClassName]: labelClassName !== null },
                        ])}
                    >
                        LinkedIn
                    </div>
                    <div className={styles.spacer} style={{ width: `${iconSize}px` }} />
                </LinkedinShareButton>
            ),
        },
        {
            id: 'whatsapp',
            button: (
                <WhatsappShareButton
                    {...shareButtonProps}
                    title={title}
                    beforeOnClick={() => {
                        onShareButtonClick('Whatsapp');
                        return Promise.resolve();
                    }}
                    // onClick={(e) => {
                    //     e.preventDefault();
                    //     e.stopPropagation();
                    // }}
                    tabIndex={focusable ? null : '-1'}
                >
                    <WhatsappIcon {...shareIconProps} />
                    <div
                        className={classNames([
                            styles.label,
                            { [labelClassName]: labelClassName !== null },
                        ])}
                    >
                        Whatsapp
                    </div>
                    <div className={styles.spacer} style={{ width: `${iconSize}px` }} />
                </WhatsappShareButton>
            ),
        },
        {
            id: 'email',
            button: (
                <EmailShareButton
                    {...shareButtonProps}
                    subject={title}
                    beforeOnClick={() => {
                        onShareButtonClick('Email');
                        return Promise.resolve();
                    }}
                    tabIndex={focusable ? null : '-1'}
                >
                    <EmailIcon {...shareIconProps} />
                    <div
                        className={classNames([
                            styles.label,
                            { [labelClassName]: labelClassName !== null },
                        ])}
                    >
                        <FormattedMessage
                            defaultMessage="Courriel"
                            description="Share option label"
                        />
                    </div>
                    <div className={styles.spacer} style={{ width: `${iconSize}px` }} />
                </EmailShareButton>
            ),
        },
    ];

    const hasShareLink = options !== null ? options.includes('copylink') : true; // default is true
    const selectedOptions =
        options !== null ? shareOptions.filter((opt) => options.includes(opt.id)) : shareOptions;

    return (
        <div className={classNames([styles.container, { [className]: className !== null }])}>
            <div className={styles.options}>
                {hasShareLink ? (
                    <div
                        className={classNames([
                            styles.item,
                            {
                                [itemClassName]: itemClassName !== null,
                                [styles.isLinkCopied]: linkCopied,
                            },
                        ])}
                    >
                        <Button
                            className={classNames([
                                styles.button,
                                styles.copyLinkButton,
                                { [buttonClassName]: buttonClassName !== null },
                            ])}
                            onClick={onClickCopy}
                            focusable={focusable}
                            withoutBootstrapStyles
                        >
                            <ShareLinkIcon {...shareIconProps} />
                            <div
                                className={classNames([
                                    styles.label,
                                    { [labelClassName]: labelClassName !== null },
                                ])}
                            >
                                <span className={styles.labelText}>
                                    {!linkCopied ? (
                                        <FormattedMessage
                                            defaultMessage="Copier le lien"
                                            description="Share button label"
                                        />
                                    ) : null}
                                    {linkCopied ? (
                                        <FormattedMessage
                                            defaultMessage="Lien copié!"
                                            description="Message displayed once text was copied successfully."
                                        />
                                    ) : null}
                                </span>
                            </div>
                            <div className={styles.spacer} style={{ width: `${iconSize}px` }} />
                        </Button>
                    </div>
                ) : null}
                {selectedOptions.map(({ id, button }) => (
                    <div
                        key={id}
                        className={classNames([
                            styles.item,
                            { [itemClassName]: itemClassName !== null },
                        ])}
                    >
                        {button}
                    </div>
                ))}
            </div>
            <button className={styles.background} onClick={onClose} />
        </div>
    );
};

Share.propTypes = propTypes;
Share.defaultProps = defaultProps;

export default Share;

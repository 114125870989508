/* eslint-disable jsx-a11y/anchor-is-valid */
import { useUrlGenerator } from '@folklore/routes';
import React from 'react';
// import * as AppPropTypes from '../../lib/PropTypes';
import { FormattedMessage } from 'react-intl';

import Link from './Link';

import styles from '../../../styles/partials/header.module.css';

const propTypes = {};

const defaultProps = {};

function Header() {
    const route = useUrlGenerator();
    return (
        <div className={styles.container}>
            <div className={styles.left}>
                <Link url={route('home')} disableModal>
                    <h3 className={styles.logo}>
                        <FormattedMessage defaultMessage="@lasource" description="Logo" />
                        <span className={styles.red}>
                            <FormattedMessage defaultMessage=".info" description="Logo" />
                        </span>
                    </h3>
                </Link>
                <Link url={route('home')} disableModal>
                    <span className={styles.slogan}>
                        <FormattedMessage
                            defaultMessage="La vérité, un point c’est tout"
                            description="Slogan"
                        />
                    </span>
                </Link>
            </div>
            <div className={styles.right}>
                <Link url={route('about')} disableModal>
                    <span className={styles.account}>
                        <FormattedMessage defaultMessage="À propos" description="Slogan" />
                    </span>
                </Link>
                {/* <Link>
                    <span className={styles.account}>
                        <FormattedMessage defaultMessage="Mon compte" description="Slogan" />
                    </span>
                </Link> */}
                <Link>
                    <div className={styles.burger}>
                        <span className={styles.line} />
                        <span className={styles.line} />
                        <span className={styles.line} />
                    </div>
                </Link>
            </div>
        </div>
    );
}

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;

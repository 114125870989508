import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
    color: PropTypes.string,
    circleColor: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    color: 'currentColor',
    circleColor: 'transparent',
    className: null,
};

const PlayIcon = ({ color, circleColor, className }) => (
    <svg
        width="80"
        height="80"
        viewBox="0 0 80 80"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={classNames([{ [className]: className !== null }])}
    >
        <path
            d="M40 80C62.0914 80 80 62.0914 80 40C80 17.9086 62.0914 0 40 0C17.9086 0 0 17.9086 0 40C0 62.0914 17.9086 80 40 80Z"
            fill={circleColor}
        />
        <path d="M28.8008 24L56.8008 40L28.8008 56V24Z" fill={color} />
    </svg>
);

PlayIcon.propTypes = propTypes;
PlayIcon.defaultProps = defaultProps;

export default PlayIcon;

import PropTypes from 'prop-types';
import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';

import { useTrackInteraction } from '../hooks/useTracking';

export const ModalsContext = React.createContext({
    container: null,
});

export const useModalsContext = () => useContext(ModalsContext) || {};

export const useModals = () => {
    const { modal, setModal, unsetModal, clearDefaultTimeout, triggerTimeout } =
        useContext(ModalsContext) || {};
    return { modal, setModal, unsetModal, clearDefaultTimeout, triggerTimeout };
};

export const useHasSeenModal = () => {
    const { hasSeenModal = false } = useContext(ModalsContext) || {};
    return hasSeenModal;
};

const propTypes = {
    children: PropTypes.node.isRequired,
    delay: PropTypes.number,
    modal: PropTypes.shape({}),
    onClose: PropTypes.func,
};

const defaultProps = {
    modal: null,
    delay: 3000,
    onClose: null,
};

export const ModalsProvider = ({ delay, children, modal: initialModal, onClose }) => {
    const [modal, setModalState] = useState(initialModal);
    const trackInteraction = useTrackInteraction();
    const timeoutRef = useRef(null);
    const [hasSeenModal, setHasSeenModal] = useState(false);

    const setModal = useCallback(
        (val) => {
            setModalState(val);
            setHasSeenModal(true);
            if (trackInteraction !== null) {
                trackInteraction(
                    'modal',
                    'click',
                    val !== null ? 'open' : 'close',
                    val !== null ? val : 'empty',
                );
            }
        },
        [setModalState, trackInteraction, setHasSeenModal],
    );

    const unsetModal = useCallback(
        (val) => {
            setModalState(null);
            if (onClose !== null) {
                const { type = null, id = null } = val || {};
                //  set cookie?
                onClose(type, id);
            }
            if (trackInteraction !== null) {
                trackInteraction('modal', 'click', 'close', 'empty');
            }
        },
        [setModalState, trackInteraction, onClose],
    );

    const triggerTimeout = useCallback(() => {
        timeoutRef.current = setTimeout(() => {
            setModal(modal !== null ? null : { type: 'video' });
        }, delay);
        return () => {
            clearTimeout(timeoutRef.current);
        };
    }, [delay, setModal]);

    const clearDefaultTimeout = useCallback(() => {
        if (timeoutRef.current !== null) {
            clearTimeout(timeoutRef.current);
        }
    }, [setModal]);

    const value = useMemo(
        () => ({
            modal,
            setModal,
            unsetModal,
            clearDefaultTimeout,
            triggerTimeout,
            hasSeenModal,
        }),
        [
            modal,
            setModalState,
            setModal,
            unsetModal,
            clearDefaultTimeout,
            triggerTimeout,
            hasSeenModal,
        ],
    );
    return <ModalsContext.Provider value={value}>{children}</ModalsContext.Provider>;
};

ModalsProvider.propTypes = propTypes;
ModalsProvider.defaultProps = defaultProps;

/* eslint-disable react/no-danger */

/* eslint-disable jsx-a11y/anchor-is-valid */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import * as images from '../../../img/articles/index';
import ShareIcon from '../icons/Share';

import styles from '../../../styles/partials/article.module.css';

const propTypes = {
    layout: PropTypes.string,
    section: PropTypes.string,
    title: PropTypes.string,
    author: PropTypes.string,
    image: PropTypes.string,
    withShare: PropTypes.bool,
    className: PropTypes.string,
    children: PropTypes.node,
};

const defaultProps = {
    layout: null,
    section: null,
    title: null,
    author: null,
    image: null,
    withShare: null,
    className: null,
    children: null,
};

function Article({ layout, section, title, author, image, withShare, className, children }) {
    const finalImage =
        image !== null && images[image]
            ? images[image]
            : images.BaieDesChaleursUneMysterieuseDepouilleConfondLaPopulation;

    return (
        <div
            className={classNames([
                styles.container,
                { [styles[layout]]: layout !== null, [className]: className !== null },
            ])}
        >
            <div
                className={styles.inner}
                style={{ backgroundImage: layout === 'full' ? `url(${finalImage})` : null }}
            >
                {layout !== 'full' ? (
                    <img src={finalImage} className={styles.image} alt={title} />
                ) : null}
                <div className={styles.card}>
                    <p className={styles.section}>{section}</p>
                    <p className={styles.title} dangerouslySetInnerHTML={{ __html: title }} />
                    <div className={styles.bottom}>
                        <p className={styles.author}>{author}</p>
                        <p className={classNames([styles.share, { [styles.visible]: withShare }])}>
                            <span className={styles.cta}>
                                <FormattedMessage
                                    defaultMessage="Partager"
                                    description="Share message"
                                />
                            </span>
                            <span className={styles.icon}>
                                <ShareIcon />
                            </span>
                        </p>
                    </div>
                </div>
                {children}
            </div>
        </div>
    );
}

Article.propTypes = propTypes;
Article.defaultProps = defaultProps;

export default Article;

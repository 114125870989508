import { useRoutes } from '@folklore/routes';
import PropTypes from 'prop-types';
import React from 'react';
import { Route, Routes } from 'react-router-dom';

import MainLayout from './layouts/Main';
import PlainLayout from './layouts/Plain';
import AboutPage from './pages/About';
import ErrorPage from './pages/Error';
import HomePage from './pages/Home';

const propTypes = {
    data: PropTypes.shape({
        articles: PropTypes.arrayOf(PropTypes.shape()),
    }),
    meta: PropTypes.shape({
        title: PropTypes.string,
    }),
};

const defaultProps = {
    data: null,
    meta: null,
};

function App({ data, meta }) {
    const routes = useRoutes() || {};
    const { articles = [], video = null } = data || {};
    return (
        <Routes>
            <Route
                path={routes.home || '/'}
                exact
                element={
                    <MainLayout page={meta} video={video}>
                        <HomePage articles={articles} meta={meta} />
                    </MainLayout>
                }
            />
            <Route
                path={routes.about}
                exact
                element={
                    <PlainLayout page={meta} video={video}>
                        <AboutPage meta={meta} />
                    </PlainLayout>
                }
            />
            <Route
                path="/:video"
                exact
                element={
                    <MainLayout page={meta} video={video}>
                        <HomePage articles={articles} meta={meta} />
                    </MainLayout>
                }
            />
            <Route
                path="/:video/:article"
                exact
                element={
                    <MainLayout page={meta} video={video}>
                        <HomePage articles={articles} meta={meta} />
                    </MainLayout>
                }
            />
            <Route
                path="*"
                element={
                    <MainLayout>
                        <ErrorPage />
                    </MainLayout>
                }
            />
        </Routes>
    );
}

App.propTypes = propTypes;
App.defaultProps = defaultProps;

export default App;

/* eslint-disable react/jsx-props-no-spreading */
import { useWindowSize } from '@folklore/hooks';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';

import useKeyboardKeys, { KEYS } from '../../hooks/useKeyboardKeys';

import { useHasSeenModal, useModals } from '../../contexts/ModalsContext';
import CloseButton from '../buttons/Button';
import CloseIcon from '../icons/Close';
import ShareModal from './Share';
import VideoModal from './Video';

import styles from '../../../styles/modals/modal.module.css';

const propTypes = {
    page: PropTypes.shape({
        title: PropTypes.string,
    }),
    video: PropTypes.string,
    // showClose: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    page: null,
    video: null,
    // showClose: false,
    className: null,
};

const Modal = ({ page, video, className }) => {
    const modalRef = useRef(null);
    const { modal = null, setModal } = useModals();

    const hasSeenModal = useHasSeenModal();
    const [showClose, setShowClose] = useState(hasSeenModal);

    useEffect(() => {
        let timeoutId = null;
        if (hasSeenModal) {
            timeoutId = setTimeout(() => {
                setShowClose(true);
            }, 10000);
        }
        return () => {
            clearTimeout(timeoutId);
        };
    }, [hasSeenModal, setShowClose]);

    const { type = null } = modal || {};
    const { height = null } = useWindowSize();
    const navigate = useNavigate();

    const onClose = useCallback(() => {
        // unsetModal(modal);
        setModal(null);
    }, [setModal]);

    const onCloseShare = useCallback(() => {
        // unsetModal(modal);
        setModal(null);
        navigate('/');
    }, [setModal]);

    const hasLoadedModal = modal !== null;
    const visible = modal !== null;

    useEffect(() => {
        if (modal !== null) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }
        return () => {
            document.body.style.overflow = '';
        };
    }, [modal]);

    useKeyboardKeys({
        [KEYS.ESCAPE]: onClose,
    });

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                    [styles.visible]: visible,
                },
            ])}
        >
            <div className={styles.wrapper} ref={modalRef}>
                <div className={styles.inner}>
                    {modal !== null && showClose ? (
                        <CloseButton
                            className={styles.closeBtn}
                            onClick={onCloseShare}
                            color="#fff"
                        >
                            <CloseIcon />
                        </CloseButton>
                    ) : null}
                    {hasLoadedModal ? (
                        <div
                            className={styles.content}
                            style={{ minHeight: height !== null && height > 0 ? height : '100vh' }}
                        >
                            {modal !== null && type === 'video' ? (
                                <VideoModal page={page} video={video} onClose={onClose} />
                            ) : null}
                            {modal !== null && type === 'share' ? (
                                <ShareModal page={page} {...modal} onClose={onCloseShare} />
                            ) : null}
                        </div>
                    ) : null}
                </div>
            </div>
            <div className={styles.lightBox} />
        </div>
    );
};

Modal.propTypes = propTypes;
Modal.defaultProps = defaultProps;

export default Modal;

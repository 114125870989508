import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
    color: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    color: 'currentColor',
    className: null,
};

const ShareIcon = ({ color, className }) => (
    <svg
        width="13"
        height="10"
        viewBox="0 0 13 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
    >
        <path
            d="M13 4.75111L7.58333 0V2.66667C2.52778 3.33333 0.722222 6.66667 0 10C1.80556 7.66667 3.97222 6.6 7.58333 6.6V9.33333L13 4.75111Z"
            fill={color}
        />
    </svg>
);

ShareIcon.propTypes = propTypes;
ShareIcon.defaultProps = defaultProps;

export default ShareIcon;

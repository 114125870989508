/* eslint-disable jsx-a11y/control-has-associated-label, jsx-a11y/anchor-is-valid, react/jsx-props-no-spreading */
import { useUrlGenerator } from '@folklore/routes';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { useTrackInteraction } from '../../hooks/useTracking';

// import CloseIcon from '../icons/Close';
import ShareIcon from '../icons/Share';
import Link from '../partials/Link';
import ShareOptions from '../partials/Share';
import Subscribe from '../partials/Subscribe';
import Video from '../partials/Video';

import national from '../../../img/medias/national.png';
import styles from '../../../styles/modals/video.module.css';

const propTypes = {
    page: PropTypes.shape({
        title: PropTypes.string,
    }),
    video: PropTypes.string,
    // onClose: PropTypes.func,
    className: PropTypes.string,
};

const defaultProps = {
    page: null,
    video: null,
    // onClose: null,
    className: null,
};

const VideoModal = ({ page, video, className }) => {
    const route = useUrlGenerator();
    const [showShare, setShowShare] = useState(false);
    const trackInteraction = useTrackInteraction();

    const onClickShare = useCallback(() => {
        if (trackInteraction !== null) {
            trackInteraction('share', 'click', showShare ? 'close' : 'open');
        }
        setShowShare(!showShare);
    }, [setShowShare, showShare, trackInteraction]);

    const onCloseShare = useCallback(() => {
        setShowShare(false);
        if (trackInteraction !== null) {
            trackInteraction('share', 'click', 'close');
        }
    }, [setShowShare, showShare, trackInteraction]);

    const onLoaded = useCallback(() => {
        if (trackInteraction !== null) {
            trackInteraction('video', 'loaded');
        }
    }, [trackInteraction]);

    const onPlay = useCallback(() => {
        if (trackInteraction !== null) {
            trackInteraction('video', 'play');
        }
    }, [trackInteraction]);

    const onPause = useCallback(() => {
        if (trackInteraction !== null) {
            trackInteraction('video', 'pause');
        }
    }, [trackInteraction]);

    const onEnd = useCallback(() => {
        if (trackInteraction !== null) {
            trackInteraction('video', 'end');
        }
    }, [trackInteraction]);

    const onVolumeChange = useCallback(() => {
        if (trackInteraction !== null) {
            trackInteraction('video', 'volume');
        }
    }, [trackInteraction]);

    const { title = null, canonical = null } = page || {};
    const finalTitle = title || 'Nouvelles | @lasource.info';

    const fullUrl = useMemo(() => {
        if (typeof window !== 'undefined' && typeof window.location !== 'undefined') {
            return `${window.location.protocol || ''}//${window.location.hostname || ''}${
                window.location.pathname || ''
            }`;
        }
        return canonical || 'https://alasource.info';
    }, [canonical]);

    const [videoUrl, setVideoUrl] = useState(null);

    useEffect(() => {
        if (videoUrl === null) {
            if (video === 'article') {
                setVideoUrl('https://player.vimeo.com/video/919739524?h=8b789d9732&title=false');
            } else if (video === 'nouvelle') {
                setVideoUrl('https://player.vimeo.com/video/919739591?h=86e74cb266&title=false');
            } else {
                setVideoUrl(
                    Math.random() > 0.5
                        ? 'https://player.vimeo.com/video/919739524?h=8b789d9732&title=false'
                        : 'https://player.vimeo.com/video/919739591?h=86e74cb266&title=false',
                );
                // OLD VIDEOS FOR REF ONLY
                // setVideoUrl(
                //     Math.random() > 0.5
                //         ? 'https://player.vimeo.com/video/890412126?h=e7b7300b35'
                //         : 'https://player.vimeo.com/video/890412562?h=851a4fed48',
                // );
            }
        }
    }, [video, videoUrl, setVideoUrl]);

    // const onClickClose = useCallback(() => {
    //     if (onClose !== null) {
    //         onClose();
    //     }
    // }, [onClose]);

    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            <div className={styles.about}>
                <Link url={route('about')} className={styles.action} clearModal>
                    <FormattedMessage defaultMessage="À propos" description="À propos" />
                </Link>

                <button type="button" className={styles.action} onClick={onClickShare}>
                    <span>
                        <FormattedMessage defaultMessage="Partager" description="À propos" />
                    </span>
                    <ShareIcon className={styles.shareIcon} />
                </button>
            </div>
            <div className={styles.videoContainer}>
                {showShare ? (
                    <ShareOptions
                        title={finalTitle}
                        url={fullUrl}
                        className={styles.share}
                        onClose={onCloseShare}
                    />
                ) : null}
                {videoUrl !== null ? (
                    <Video
                        className={styles.player}
                        video={{
                            url: `${videoUrl}&app_id=58479&color=ffff00&muted=1&autoplay=1&dnt=1`, // &controls=1&muted=1&autoplay=1
                            provider: 'vimeo',
                            type: 'video',
                            width: 768,
                            height: 480,
                        }}
                        onLoaded={onLoaded}
                        onPlay={onPlay}
                        onPause={onPause}
                        onEnd={onEnd}
                        onVolumeChange={onVolumeChange}
                    />
                ) : null}
            </div>
            <h1 className={styles.title}>
                <strong>
                    <FormattedMessage defaultMessage="Abonnez-vous" description="CTA" />
                </strong>{' '}
                <FormattedMessage defaultMessage="à des médias d’ici :" description="CTA" />
            </h1>
            <Subscribe className={styles.buttons} />
            <div className={styles.collab}>
                <span className={styles.text}>
                    <FormattedMessage defaultMessage="Avec la collaboration de" description="CTA" />
                </span>
                <img className={styles.national} src={national} alt="National" />
            </div>
        </div>
    );
};

VideoModal.propTypes = propTypes;
VideoModal.defaultProps = defaultProps;

export default VideoModal;

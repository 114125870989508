/* eslint-disable jsx-a11y/anchor-is-valid */

/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { defineMessages } from 'react-intl';

import { useModals } from '../../contexts/ModalsContext';
import PageMeta from '../partials/PageMeta';
import Subscribe from '../partials/Subscribe';

import styles from '../../../styles/pages/about.module.css';

const messages = defineMessages({
    metaTitle: {
        defaultMessage: 'À propos | @lasource.info',
        description: 'Page title',
    },
});

const propTypes = {
    // intl: AppPropTypes.intl.isRequired,
    meta: PropTypes.shape({
        title: PropTypes.string,
    }),
};

const defaultProps = {
    meta: null,
};

function AboutPage({ meta }) {
    const { clearDefaultTimeout } = useModals();

    useEffect(() => {
        clearDefaultTimeout();
        setTimeout(() => {
            clearDefaultTimeout();
        }, 1000);
    }, [clearDefaultTimeout]);

    return (
        <div className={styles.container}>
            <PageMeta {...meta} title={messages.metaTitle} />
            <h1 className={styles.title}>
                Bannis par Meta, les médias locaux comptent sur votre soutien
            </h1>
            <div className={styles.content}>
                <p className={styles.paragraph}>
                    Pour contourner la Loi sur les nouvelles en ligne (LNE), le géant Meta a bloqué
                    les contenus journalistiques de Facebook et d’Instagram. Nous, Acadie Nouvelle,
                    L’Actualité, Les Affaires, Les Coops de l’info (regroupant Le Soleil, Le
                    Quotidien, Le Nouvelliste, La Voix de l’Est, La Tribune, Le Droit, Les As de
                    l’info), Le Devoir et URBANIA, unissons nos voix pour dénoncer cette censure
                    invisible qui fragilise l’industrie des médias.
                </p>
                <p className={styles.paragraph}>
                    Près de quatre mois se sont écoulés depuis le blocage, et l’espoir que Meta se
                    conforme à la nouvelle loi est de plus en plus mince. Nous faisons aujourd’hui
                    appel à la responsabilité citoyenne des Québécois et des Canadiens pour garantir
                    la poursuite de nos activités journalistiques, cruciales au maintien d’une saine
                    démocratie.
                </p>
                <p className={styles.paragraph}>
                    Cette riposte radicale de Meta va à l’encontre des fondements mêmes de la
                    liberté de presse et de l’accès à l’information. Du jour au lendemain, sans que
                    les utilisateurs s’en rendent réellement compte, les contenus d’information se
                    sont évaporés des deux médias sociaux les plus fréquentés.
                </p>
                <h2 className={styles.subtitle}>Un bras de fer qui coûte cher</h2>
                <p className={styles.paragraph}>
                    Dire que l’industrie des médias est en crise relève désormais du lieu commun.
                    Les licenciements massifs récents au sein des médias ont démontré de manière
                    concrète l’étendue du problème. Nous appréhendons maintenant les contrecoups
                    d’une baisse de visibilité de nos contenus entraînant naturellement un déclin de
                    nos revenus publicitaires, déjà affectés depuis plusieurs années par la
                    migration d’une part de la publicité vers les géants du Web.
                </p>
                <p className={styles.paragraph}>
                    Avant le blocage, Facebook et Instagram constituaient des leviers majeurs pour
                    la mise en valeur de nos contenus. Selon une récente enquête du Centre d’étude
                    sur les médias, 44 % des Québécois, et 70 % dans la tranche d’âge des 18-34 ans,
                    considéraient Facebook et Instagram comme des sources importantes
                    d’informations. Il s’agit d’un nombre important de Québécoises, de Québécois et
                    de francophones de l’Atlantique, et plus particulièrement de représentants des
                    générations montantes, pour qui les habitudes de consultation de l’information
                    ont été bousculées. Il y a de quoi sonner l’alarme.
                </p>
                <p className={styles.paragraph}>
                    En empêchant la libre circulation de contenus journalistiques produits par des
                    médias crédibles, Meta ouvre la porte toute grande à la diffusion de fausses
                    informations à un moment où les faits et la clarté sont essentiels. Étant donné
                    ces habitudes de consultation des contenus déjà bien installées, le géant porte
                    atteinte à un des piliers fondamentaux de la démocratie : l’accès à une
                    information crédible et vérifiée.
                </p>
                <h2 className={styles.subtitle}>Agir maintenant</h2>
                <p className={styles.paragraph}>
                    Quiconque lira cette déclaration qualifiera inévitablement la situation de
                    préoccupante. Pourtant, l’indignation populaire demeure modérée. Facebook et
                    Instagram n’ont connu aucune baisse de fréquentation significative, et les
                    Canadiens s’y nourrissent plus que jamais de contenus édulcorés.
                </p>
                <p className={styles.paragraph}>
                    Vous pouvez agir à l’échelle individuelle pour retrouver une information fiable
                    et vérifiée. En vous abonnant, en téléchargeant nos applications ou en vous
                    inscrivant à nos infolettres, vous faites un geste concret pour soutenir des
                    médias qui respectent votre intelligence. Vous soutenez le travail de milliers
                    de journalistes et d’artisans qui exercent un métier crucial et exigeant au
                    bénéfice de notre société.
                </p>
                <h2 className={styles.lastTitle}>
                    Ensemble, agissons maintenant pour préserver la vitalité de nos médias locaux.
                </h2>
                <Subscribe className={styles.subscribe} buttonClassName={styles.button} invert />
            </div>
        </div>
    );
}

AboutPage.propTypes = propTypes;
AboutPage.defaultProps = defaultProps;

export default AboutPage;

import { useTracking } from '@folklore/tracking';
import { useCallback } from 'react';

export const useTrackInteraction = () => {
    const tracking = useTracking();
    const trackEvent = useCallback(
        (category, action, label, value, data) => {
            if (category !== null && action !== null) {
                tracking.trackEvent(category, action, label, value, data);
            }
        },
        [tracking],
    );
    return trackEvent;
};

export const useTrackSocial = () => {
    const tracking = useTracking();
    const trackSocial = useCallback(
        (category, action, label, value, data) => {
            if (category !== null && action !== null) {
                tracking.trackSocial(category, action, label, value, data);
            }
        },
        [tracking],
    );
    return trackSocial;
};

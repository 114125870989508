/* eslint-disable jsx-a11y/anchor-is-valid */
import { useUrlGenerator } from '@folklore/routes';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import Link from './Link';

import styles from '../../../styles/partials/footer.module.css';

const propTypes = {};

const defaultProps = {};

function Footer() {
    const date = new Date();
    const year = date.getFullYear();
    const route = useUrlGenerator();

    return (
        <div className={styles.container}>
            <ul className={styles.items}>
                <li>
                    <Link url={route('about')} disableModal className={styles.item}>
                        <FormattedMessage
                            defaultMessage="À propos de nous"
                            description="Footer item"
                        />
                    </Link>
                </li>
                <li>
                    <Link className={styles.item}>
                        <FormattedMessage
                            defaultMessage="@lasource.info ©"
                            description="Footer item"
                        />{' '}
                        {year || null}
                    </Link>
                </li>
            </ul>
        </div>
    );
}

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { FormattedMessage } from 'react-intl';

import Link from './Link';

import styles from '../../../styles/partials/menu.module.css';

const propTypes = {};

const defaultProps = {};

function Menu() {
    return (
        <div className={styles.container}>
            <ul className={styles.items}>
                <li>
                    <Link className={styles.item}>
                        <FormattedMessage defaultMessage="Actualités" description="Menu item" />
                    </Link>
                </li>
                <li>
                    <Link className={styles.item}>
                        <FormattedMessage defaultMessage="Nouvelles" description="Menu item" />
                    </Link>
                </li>
                <li>
                    <Link className={styles.item}>
                        <FormattedMessage defaultMessage="Mon compte" description="Menu item" />
                    </Link>
                </li>
            </ul>
        </div>
    );
}

Menu.propTypes = propTypes;
Menu.defaultProps = defaultProps;

export default Menu;

/* eslint-disable react/no-array-index-key */
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import { defineMessages, useIntl } from 'react-intl';

import * as AppPropTypes from '../../lib/PropTypes';
import { isMessage } from '../../lib/utils';

const messages = defineMessages({
    title: {
        defaultMessage: 'Nouvelles | @lasource.info',
        description: 'Page title',
    },
});

const propTypes = {
    title: PropTypes.oneOfType([AppPropTypes.message, PropTypes.string]),
    description: PropTypes.string,
    image: PropTypes.string,
};

const defaultProps = {
    title: messages.title,
    description: null,
    image: null,
};

function PageMeta({ title, description, image }) {
    const intl = useIntl();

    return (
        <Helmet>
            <title>{isMessage(title) ? intl.formatMessage(title) : title}</title>
            {description !== null ? <meta name="description" content={description} /> : null}
            {image !== null ? <meta property="og:image" content={image} /> : null}
        </Helmet>
    );
}

PageMeta.propTypes = propTypes;
PageMeta.defaultProps = defaultProps;

export default React.memo(PageMeta);
